import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1280.000000 1276.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1276.000000) scale(0.100000,-0.100000)">
					<path d="M6045 12744 c-22 -2 -103 -9 -180 -15 -910 -70 -1834 -355 -2631
-812 -548 -314 -980 -648 -1424 -1104 -268 -274 -501 -557 -705 -859 -573
-847 -929 -1792 -1049 -2784 -32 -270 -35 -305 -46 -575 -42 -1072 212 -2188
718 -3155 400 -766 955 -1443 1634 -1996 268 -219 635 -468 933 -634 109 -61
450 -227 577 -281 1430 -606 2986 -682 4469 -219 165 52 473 168 639 240 91
40 446 215 520 257 439 248 818 518 1175 837 94 83 384 371 470 466 700 771
1212 1728 1459 2725 258 1046 256 2085 -8 3125 -167 656 -436 1271 -816 1865
-249 389 -541 746 -896 1095 -311 306 -625 560 -959 778 -164 107 -208 133
-350 214 -399 227 -777 394 -1191 527 -475 152 -858 232 -1389 287 -140 15
-844 28 -950 18z m950 -169 c1233 -123 2361 -579 3310 -1339 335 -268 703
-634 971 -966 671 -831 1125 -1855 1293 -2915 28 -178 51 -378 51 -450 0 -74
9 -89 52 -89 l33 -1 3 -427 2 -428 -39 0 -39 0 -7 -77 c-83 -1026 -422 -2022
-982 -2881 -131 -201 -392 -550 -499 -667 -11 -11 -50 -56 -88 -100 -39 -44
-149 -159 -246 -255 -645 -642 -1393 -1119 -2257 -1439 -960 -355 -2046 -464
-3083 -311 -667 98 -1341 317 -1947 630 -390 203 -792 469 -1123 745 -117 97
-437 391 -448 411 -4 7 -52 60 -107 116 -497 511 -918 1162 -1230 1903 -225
536 -393 1241 -436 1832 l-12 162 -36 3 -36 3 -3 422 -2 422 42 3 43 3 7 80
c43 461 154 977 306 1425 125 366 324 802 498 1092 30 50 54 92 54 93 0 6 167
264 234 360 101 147 245 331 381 490 130 152 440 469 563 577 42 37 88 77 102
89 166 144 405 326 601 457 783 522 1635 850 2579 991 132 20 406 47 545 55
170 9 797 -3 950 -19z"/>
<path d="M6145 11119 c34 -5 10 -7 -75 -5 -80 2 -109 1 -80 -4 34 -6 24 -7
-40 -5 l-85 3 55 -12 c47 -10 51 -13 28 -18 -15 -3 -29 -2 -30 3 -2 4 -32 10
-68 13 -46 5 -59 3 -45 -4 14 -7 5 -8 -32 -3 -35 4 -58 1 -68 -7 -8 -7 -25
-10 -40 -6 -16 4 -25 2 -25 -5 0 -7 -13 -9 -37 -6 l-38 6 40 -13 c34 -11 36
-13 17 -18 -12 -4 -22 -2 -22 3 0 5 -9 9 -21 9 -11 0 -17 -4 -14 -10 3 -5 1
-10 -5 -10 -7 0 -10 7 -6 15 3 10 -1 15 -12 14 -30 -1 -130 -24 -138 -32 -4
-4 -23 -7 -41 -7 -19 0 -41 -7 -49 -15 -14 -13 -12 -15 18 -16 l33 -1 -30 -9
c-22 -6 -17 -7 20 -3 28 3 54 10 60 15 6 6 -3 7 -23 3 -21 -4 -32 -3 -32 5 0
14 67 15 76 0 3 -6 17 -8 33 -4 56 15 69 17 156 31 104 16 139 25 77 20 -27
-3 -40 0 -36 6 4 7 34 8 78 5 39 -3 89 -2 111 2 l40 9 -35 1 c-23 1 -30 4 -22
10 15 9 62 3 62 -9 0 -5 12 -6 28 -4 15 2 72 6 127 10 54 3 110 10 123 15 13
5 40 8 60 8 l37 -2 -35 -8 c-34 -8 -34 -8 5 -3 22 2 96 6 165 8 86 3 105 2 60
-3 l-65 -8 83 -1 c53 -1 81 2 77 8 -4 6 16 10 52 11 32 0 81 4 108 8 33 5 3 7
-90 6 -115 -2 -125 -1 -55 4 54 5 -18 7 -200 8 -168 0 -264 -3 -235 -8z m278
-6 c-35 -2 -91 -2 -125 0 -35 2 -7 3 62 3 69 0 97 -1 63 -3z m-285 -20 c-16
-2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z m-111 -9 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-241 -22 c-10 -2 -22 0 -28 6 -6 6 0
7 19 4 21 -5 23 -7 9 -10z m-269 -28 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-27 -18 c0 -2 -11 -6 -25 -8 -14 -3 -25 -1 -25 3 0 5 11 9 25 9
14 0 25 -2 25 -4z"/>
<path d="M6796 11103 c-4 -4 -24 -7 -44 -5 -20 1 -30 -1 -22 -4 8 -3 22 -8 30
-11 8 -4 18 -5 23 -5 4 1 7 -2 7 -6 0 -10 13 -11 145 -13 58 -1 107 -4 109 -8
3 -4 -12 -6 -32 -4 -20 1 -3 -4 38 -13 41 -8 93 -12 115 -9 27 4 35 2 26 -4
-10 -7 -1 -8 30 -5 31 2 37 1 19 -4 l-25 -8 29 -6 c16 -3 38 -1 47 5 17 10 16
12 -3 25 -29 21 -86 32 -93 18 -3 -8 -7 -7 -11 1 -4 8 -15 11 -31 7 -17 -5
-24 -3 -20 3 4 6 0 14 -9 17 -8 3 -12 2 -9 -4 3 -6 -4 -10 -17 -10 -19 0 -20
2 -8 11 11 8 3 9 -33 5 -26 -3 -46 -2 -43 3 7 11 -89 21 -161 16 -47 -3 -51
-2 -23 4 l35 8 -31 2 c-17 0 -34 -2 -38 -6z m143 -17 c-2 -2 -26 -6 -54 -9
-34 -4 -42 -3 -25 3 25 8 87 13 79 6z m14 -23 c-7 -2 -21 -2 -30 0 -10 3 -4 5
12 5 17 0 24 -2 18 -5z m184 -29 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z m96 -1 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M6581 11086 c2 -2 36 -6 74 -9 41 -3 60 -2 45 3 -25 8 -127 13 -119
6z"/>
<path d="M7343 11024 c9 -3 17 -10 17 -16 0 -5 7 -7 16 -4 8 3 13 2 10 -3 -3
-4 -19 -8 -36 -9 -17 0 -28 3 -25 8 3 4 -5 6 -17 2 -21 -5 -21 -5 3 -14 13 -6
52 -10 86 -10 39 0 62 -4 60 -10 -3 -16 49 -27 81 -18 27 8 27 9 4 16 -18 5
-21 9 -10 16 9 7 7 8 -7 4 -11 -3 -42 -2 -70 3 -29 5 -40 10 -25 11 l25 2 -25
7 c-14 4 -43 11 -65 14 -22 3 -32 3 -22 1z"/>
<path d="M5264 11000 c-5 -5 -30 -12 -54 -15 -62 -8 -232 -60 -224 -68 4 -4
-6 -7 -22 -7 -22 0 -25 -3 -14 -10 8 -5 26 -7 40 -4 22 5 23 4 7 -6 -9 -6 -27
-8 -38 -5 -15 5 -19 4 -15 -4 9 -14 -28 -15 -53 -1 -13 6 -38 3 -85 -12 -70
-23 -84 -38 -38 -39 24 0 24 -1 7 -8 -11 -5 -25 -4 -32 1 -19 15 -71 -1 -100
-30 -15 -15 -20 -18 -12 -8 13 18 12 19 -6 12 -11 -5 -30 -11 -42 -13 -13 -3
-20 -9 -17 -14 4 -8 -16 -13 -38 -10 -4 0 -5 -8 -3 -19 3 -17 1 -18 -15 -8
-14 9 -30 6 -81 -16 -35 -15 -71 -33 -79 -41 -8 -7 -25 -16 -38 -20 -12 -3
-20 -9 -18 -13 2 -4 -6 -8 -18 -8 -13 -1 -58 -21 -100 -45 -43 -24 -86 -49
-97 -55 -10 -6 -16 -14 -12 -17 3 -4 -2 -7 -12 -7 -22 0 -99 -38 -124 -61 -10
-10 -21 -16 -24 -13 -2 2 -18 -9 -36 -25 -17 -16 -31 -27 -31 -24 0 2 -13 -4
-29 -13 -17 -10 -28 -21 -26 -25 3 -3 -8 -9 -23 -12 -15 -3 -39 -18 -54 -32
-16 -14 -28 -23 -28 -19 0 4 -9 -1 -20 -11 -16 -14 -17 -20 -8 -27 9 -5 3 -8
-14 -8 -16 0 -28 -5 -28 -10 0 -6 -5 -9 -12 -8 -7 2 -17 -7 -23 -19 -5 -13
-19 -23 -30 -23 -11 0 -39 -18 -63 -40 -24 -22 -63 -54 -87 -71 -39 -28 -43
-34 -33 -53 10 -19 10 -20 -5 -7 -15 11 -23 8 -64 -27 -56 -47 -90 -82 -78
-82 5 0 17 7 27 17 10 9 18 11 18 5 0 -15 -38 -35 -55 -28 -7 2 -19 -3 -27
-12 -7 -8 -8 -13 -3 -10 6 3 16 0 23 -8 7 -8 10 -15 7 -15 -3 -1 -8 -2 -12 -3
-20 -4 -47 -25 -41 -32 11 -10 80 45 72 58 -4 6 -3 9 2 5 5 -3 22 5 38 18 25
20 28 26 17 39 -8 9 -9 16 -3 16 6 0 12 -6 15 -12 2 -7 8 -10 12 -5 5 4 4 12
-2 18 -8 8 -6 16 8 30 24 24 37 25 16 1 -10 -10 -14 -22 -10 -25 3 -4 13 2 20
13 8 11 18 18 22 16 4 -3 25 12 46 34 26 27 36 44 31 57 -5 16 -5 16 7 2 14
-18 13 -19 80 35 33 27 35 30 19 42 -15 12 -13 13 17 7 23 -4 32 -3 28 4 -4 6
0 14 9 17 9 4 13 2 8 -5 -3 -6 6 -3 21 7 15 10 23 20 16 22 -19 6 -12 20 8 15
20 -5 115 60 104 71 -4 3 1 6 11 6 10 0 27 5 38 11 17 9 18 13 7 21 -11 8 -10
8 4 4 25 -8 44 4 26 15 -10 6 -6 8 11 7 16 -2 24 2 23 10 -2 6 7 12 20 12 22
0 82 39 60 40 -6 0 -18 -6 -25 -13 -8 -9 -21 -11 -33 -6 -19 7 -19 8 2 8 12 1
25 6 29 12 3 6 16 8 28 5 11 -2 32 2 46 10 l25 15 -27 -5 c-15 -4 -29 -2 -32
3 -8 12 16 20 45 14 17 -3 43 6 78 26 28 17 56 31 61 31 4 0 10 3 12 8 2 4 31
20 65 36 34 16 59 34 56 39 -4 6 1 8 12 4 9 -4 23 -3 29 2 23 18 149 80 176
86 16 3 34 13 40 21 7 7 16 12 20 9 5 -3 20 0 35 7 19 8 22 14 13 20 -8 5 -2
6 15 2 21 -5 27 -3 27 10 0 11 9 16 29 16 29 0 132 38 142 52 3 5 13 6 22 2 9
-3 17 -1 17 4 0 6 8 11 18 10 9 0 42 8 72 17 30 9 81 23 113 32 34 9 57 21 57
29 0 8 10 14 23 15 21 1 21 1 -3 9 -15 5 -18 8 -8 9 9 1 24 4 32 7 12 4 15 2
11 -9 -5 -10 1 -14 19 -14 14 0 23 3 21 7 -3 4 2 10 10 14 8 3 15 1 15 -5 0
-7 10 -9 27 -4 37 9 37 10 21 34 -9 13 -18 17 -24 11z m13 -26 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-165 -31 c-6 -2 -10 -9 -6 -14 3 -5
-2 -9 -10 -9 -17 0 -21 12 -9 23 3 4 13 7 22 6 11 0 12 -2 3 -6z m-65 -19 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-169 -59 c-2 -3 -20 -7
-39 -10 l-34 -5 35 14 c32 12 50 13 38 1z m-168 -65 c0 -5 -6 -10 -14 -10 -8
0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4 21 -10z m-127 -57 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-96 -35 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-23 -3 c-7 -16 -24 -21 -24 -6 0 8 7 13 27 20 1 1
0 -6 -3 -14z m-34 -32 c0 -3 -9 -3 -21 0 -12 3 -22 0 -26 -9 -3 -8 -11 -14
-17 -14 -7 0 -22 -9 -34 -20 -12 -12 -22 -17 -22 -12 0 9 35 41 66 58 17 10
54 8 54 -3z m-175 -63 c-3 -5 -1 -10 6 -10 7 0 10 -2 8 -5 -3 -2 -16 -1 -29 2
-22 6 -23 7 -4 14 26 10 26 10 19 -1z m-51 -35 c-4 -9 -17 -15 -33 -14 -20 1
-22 2 -6 6 11 3 24 9 30 14 13 13 15 11 9 -6z m-65 -46 c-22 -14 -33 -10 -19
6 7 8 17 12 22 9 6 -4 5 -10 -3 -15z m-94 -49 c-3 -5 -10 -10 -16 -10 -5 0 -9
5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-315 -189 c0 -6 -4 -12 -8 -15 -5
-3 -9 1 -9 9 0 8 4 15 9 15 4 0 8 -4 8 -9z m-30 -28 c0 -2 -6 -11 -14 -18 -11
-12 -13 -10 -13 10 0 16 4 22 13 18 8 -3 14 -7 14 -10z m-205 -153 c-16 -16
-30 -28 -32 -26 -8 7 22 46 34 46 7 0 13 5 13 12 0 6 3 8 7 5 3 -4 -6 -21 -22
-37z m-65 -50 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9
-4 9 -10z m-35 -36 c-17 -17 -22 -17 -28 -1 -3 7 5 13 19 13 20 2 21 0 9 -12z"/>
<path d="M7555 10970 c3 -5 16 -10 28 -11 16 -1 12 -4 -13 -12 l-35 -11 30 -4
c17 -2 35 -7 40 -12 6 -4 57 -22 115 -39 58 -18 110 -33 115 -35 6 -2 17 -6
25 -9 8 -4 51 -18 95 -33 44 -14 85 -30 90 -34 11 -9 87 -38 119 -45 10 -2 24
-9 31 -15 7 -6 21 -7 30 -3 12 4 16 3 11 -5 -4 -7 7 -10 36 -8 24 2 35 1 25
-1 -23 -6 -17 -24 12 -34 14 -6 20 -5 16 1 -3 6 -2 10 2 10 16 0 63 -25 63
-33 0 -4 -9 -3 -19 3 -24 12 -34 13 -27 1 3 -5 26 -14 51 -20 25 -6 43 -16 40
-21 -8 -13 15 -13 35 0 13 9 13 10 0 10 -8 0 -26 10 -40 21 l-25 20 33 -16
c19 -9 36 -15 38 -12 5 5 -89 47 -105 47 -6 0 -11 4 -11 8 0 15 -72 43 -92 36
-14 -4 -18 -2 -14 5 4 6 -1 14 -11 17 -10 3 -26 9 -35 14 -22 12 -190 81 -203
84 -5 1 -16 5 -24 10 -35 22 -134 44 -158 35 -31 -12 -43 -11 -43 1 0 6 10 10
23 10 21 1 21 1 -5 15 -19 11 -33 12 -50 6 l-23 -10 25 0 c18 -1 21 -4 12 -13
-8 -8 -15 -8 -24 -1 -7 6 -31 14 -53 18 -33 6 -37 9 -20 15 28 12 43 12 25 0
-12 -8 -10 -10 11 -10 50 0 39 14 -28 35 -102 32 -126 37 -118 25z m335 -110
c12 -8 11 -10 -7 -10 -13 0 -23 5 -23 10 0 13 11 13 30 0z m34 -15 c-4 -8 -11
-15 -16 -15 -6 0 -5 6 2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m110 -36 c12
-11 -33 -4 -64 10 -22 9 -20 10 15 5 22 -4 44 -10 49 -15z m31 -19 c-3 -5 -11
-10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m35 -9 c0 -5 -7
-11 -14 -14 -10 -4 -13 -1 -9 9 6 15 23 19 23 5z m48 -28 c16 -16 16 -16 -1
-10 -10 4 -23 7 -28 7 -5 0 -9 5 -9 10 0 15 20 12 38 -7z m49 -9 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m23 -24 c0 -5 -4 -10 -9 -10 -6 0
-13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z"/>
<path d="M5138 10923 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4898 10893 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6145 10659 c-354 -17 -688 -76 -1028 -180 -241 -74 -548 -203 -762
-321 -547 -302 -993 -685 -1359 -1168 -472 -622 -751 -1316 -852 -2114 -21
-170 -30 -629 -15 -808 87 -1037 503 -1959 1210 -2682 686 -701 1564 -1133
2541 -1251 184 -22 586 -30 785 -16 667 49 1310 254 1882 599 639 386 1183
958 1540 1617 424 783 593 1674 487 2570 -87 747 -376 1464 -830 2067 -41 54
-82 108 -93 120 -285 341 -556 592 -903 834 -204 143 -585 347 -818 439 -335
132 -707 227 -1055 270 -116 15 -473 36 -545 33 -19 0 -102 -4 -185 -9z m695
-83 c395 -51 733 -137 1060 -268 1373 -552 2351 -1778 2589 -3243 44 -269 54
-431 48 -755 -7 -362 -18 -468 -83 -790 -96 -474 -286 -947 -555 -1380 -205
-331 -503 -683 -784 -925 -38 -33 -79 -68 -90 -78 -48 -44 -260 -200 -370
-274 -966 -642 -2168 -849 -3295 -568 -581 146 -1097 399 -1571 771 -158 124
-181 145 -374 338 -330 331 -560 647 -772 1062 -309 606 -456 1219 -457 1914
0 279 9 409 44 646 136 920 613 1831 1281 2442 150 138 163 149 269 233 655
516 1396 810 2240 889 149 14 674 5 820 -14z"/>
<path d="M6015 10309 c-247 -22 -583 -90 -825 -168 -204 -65 -212 -68 -375
-140 -454 -201 -816 -446 -1167 -789 -567 -555 -950 -1254 -1104 -2011 -35
-175 -75 -450 -67 -463 2 -5 15 -8 27 -8 22 0 24 5 35 108 126 1121 754 2151
1696 2783 809 542 1815 758 2764 594 637 -111 1224 -371 1726 -764 288 -226
609 -569 807 -861 358 -530 583 -1134 652 -1750 11 -104 12 -105 39 -108 33
-4 33 3 13 158 -162 1217 -847 2260 -1897 2892 -480 290 -1056 473 -1654 528
-130 12 -527 11 -670 -1z"/>
<path d="M7413 7108 c-4 -7 -8 -16 -8 -20 0 -13 -15 -21 -15 -8 0 11 -3 11
-12 2 -10 -10 -10 -15 1 -21 9 -6 10 -11 2 -15 -6 -4 -11 -2 -11 4 0 18 -42
-9 -47 -31 -5 -17 -15 -23 -34 -21 -4 1 -5 -8 -2 -18 4 -14 0 -20 -11 -20 -9
0 -18 -12 -22 -27 -4 -16 -10 -32 -14 -38 -20 -28 -60 -108 -60 -120 0 -8 -7
-15 -15 -15 -8 0 -15 -9 -15 -19 0 -10 -7 -21 -14 -24 -8 -3 -18 -22 -22 -41
-3 -21 -10 -33 -17 -29 -7 4 -8 -11 -4 -48 4 -30 7 -65 7 -78 0 -31 9 -22 50
54 18 33 39 69 45 80 15 25 69 126 170 318 42 81 72 147 66 147 -5 0 -13 -6
-18 -12z"/>
<path d="M4590 6771 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6930 6567 c0 -16 -4 -26 -10 -22 -6 3 -10 -4 -10 -18 0 -13 -8 -32
-19 -41 -10 -9 -26 -38 -36 -64 -16 -46 -20 -56 -44 -115 -13 -33 -15 -221 -2
-213 5 3 11 -8 13 -24 3 -17 0 -30 -6 -30 -6 0 -4 -5 4 -10 12 -7 12 -12 2
-22 -6 -6 -12 -21 -12 -32 1 -16 6 -11 21 17 11 21 17 43 13 49 -4 6 -3 8 3 5
15 -9 153 231 154 268 1 35 -41 225 -56 253 l-14 27 -1 -28z"/>
<path d="M4680 6541 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2460 6233 c0 -52 20 -268 36 -383 136 -1017 673 -1951 1484 -2581
504 -392 1116 -661 1735 -763 244 -40 385 -51 650 -51 267 0 414 12 645 51
1293 218 2393 1082 2929 2301 174 395 295 889 316 1290 3 59 8 120 10 136 4
24 2 27 -25 27 -18 0 -30 -5 -30 -13 0 -60 -25 -314 -45 -452 -87 -606 -350
-1238 -719 -1730 -642 -856 -1553 -1389 -2616 -1532 -190 -25 -745 -25 -934 0
-499 68 -972 221 -1381 449 -945 527 -1628 1418 -1885 2458 -64 261 -110 575
-110 753 l0 67 -30 0 c-26 0 -30 -4 -30 -27z"/>
<path d="M6801 6064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7650 5758 c-12 -22 -12 -22 6 -6 10 10 15 20 12 24 -4 3 -12 -5 -18
-18z"/>
<path d="M7611 5687 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M7586 5643 c-3 -10 -26 -54 -51 -97 -53 -93 -56 -105 -15 -48 17 23
30 46 30 52 0 5 5 10 10 10 6 0 10 6 10 13 0 8 7 28 16 46 9 18 14 35 11 37
-3 3 -8 -3 -11 -13z"/>
<path d="M7476 5433 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M7628 4698 c-16 -5 -28 -14 -28 -18 0 -5 -21 -11 -47 -13 -27 -2 38
-3 142 -3 105 0 162 1 128 3 -44 3 -63 8 -63 17 0 25 -80 34 -132 14z"/>
<path d="M8500 10601 c0 -5 -10 -11 -22 -13 -19 -3 -13 -8 30 -27 28 -13 52
-27 52 -32 0 -5 12 -4 26 1 36 14 44 12 37 -6 -4 -11 -8 -12 -13 -4 -5 9 -10
9 -14 1 -4 -6 7 -17 26 -26 18 -9 56 -29 83 -45 32 -18 54 -25 60 -19 5 5 18
9 30 9 18 -1 18 -2 -5 -15 -22 -12 -23 -14 -7 -20 9 -4 24 -2 31 4 10 8 16 9
21 1 3 -5 -1 -10 -9 -10 -9 0 -16 -4 -16 -10 0 -5 9 -10 20 -10 11 0 19 -6 18
-12 -2 -8 6 -12 22 -10 18 1 21 -1 11 -7 -10 -7 -2 -15 31 -35 51 -29 62 -32
55 -12 -4 10 0 12 14 9 22 -6 25 -19 7 -26 -15 -6 18 -33 72 -59 19 -10 31
-17 26 -18 -15 0 25 -34 77 -64 26 -15 47 -31 47 -35 0 -12 103 -86 119 -86 9
-1 15 -7 13 -15 -1 -10 3 -8 13 4 8 11 15 23 15 28 0 12 -24 10 -38 -4 -8 -8
-12 -9 -12 -1 0 6 6 13 12 15 7 3 -37 43 -97 91 -60 48 -111 84 -114 81 -3 -3
1 -9 9 -14 11 -7 12 -10 2 -10 -7 0 -10 -5 -6 -12 4 -7 3 -8 -4 -4 -7 4 -12
17 -12 30 0 22 -17 46 -33 46 -4 0 -5 -4 -2 -10 3 -5 -1 -10 -9 -10 -10 0 -15
8 -13 23 2 22 -2 24 -38 12 -19 -6 -19 -6 -2 8 18 15 18 16 -5 31 -13 9 -38
21 -56 27 -18 6 -30 15 -27 19 3 4 -2 11 -11 14 -8 3 -12 2 -9 -4 3 -5 -1 -7
-9 -4 -8 3 -12 12 -9 20 3 8 -1 14 -9 14 -8 0 -23 8 -34 19 -37 34 -90 54
-113 42 -13 -7 -22 -8 -26 -1 -3 6 4 10 17 10 22 1 22 1 -6 23 -27 20 -201
117 -211 117 -3 0 -5 -4 -5 -9z m70 -41 c0 -13 -11 -13 -30 0 -12 8 -11 10 8
10 12 0 22 -4 22 -10z m115 -64 c17 -9 22 -16 15 -21 -6 -4 -19 0 -28 8 -9 8
-24 17 -32 20 -12 4 -12 6 1 6 9 1 29 -5 44 -13z m262 -172 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m266 -194 c-3 -3 -8 1 -10 8 -3 6 -11 12
-18 12 -17 0 -47 29 -40 40 2 4 20 -6 39 -23 19 -17 32 -34 29 -37z m52 -30
c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m45
-35 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z"/>
<path d="M9367 10004 c-3 -18 -1 -24 9 -20 7 3 16 1 19 -4 4 -6 1 -10 -5 -10
-6 0 -8 -4 -5 -10 3 -5 11 -10 16 -10 6 0 8 4 5 8 -2 4 4 8 14 8 10 0 16 -5
13 -10 -4 -5 -1 -6 6 -1 15 9 4 22 -23 27 -11 1 -22 10 -24 18 -5 13 -3 13 14
0 19 -14 19 -14 0 8 -25 28 -33 28 -39 -4z"/>
<path d="M9410 9945 c0 -13 40 -43 45 -35 4 6 16 10 28 10 22 1 22 1 3 15 -28
21 -50 19 -42 -2 6 -17 5 -17 -8 0 -13 16 -26 23 -26 12z"/>
<path d="M9474 9895 c11 -8 17 -15 14 -15 -4 0 8 -15 26 -32 106 -106 146
-141 153 -134 4 4 2 10 -4 14 -8 5 -9 13 -3 22 8 13 10 12 10 -2 0 -10 5 -18
10 -18 16 0 11 16 -12 36 -12 10 -19 15 -16 9 3 -5 -1 -16 -9 -24 -13 -12 -14
-11 -8 8 7 22 -10 30 -18 9 -3 -8 -6 -6 -6 5 -1 9 6 17 14 17 12 0 12 3 -2 18
-10 11 -21 18 -24 16 -3 -2 -12 6 -19 17 -8 10 -19 19 -26 19 -6 0 -19 10 -30
23 -19 22 -19 22 2 5 11 -10 25 -18 30 -18 25 1 -45 40 -71 39 -29 0 -29 0
-11 -14z m103 -74 c8 -12 19 -19 24 -16 5 4 9 2 9 -4 0 -23 -18 -16 -60 26
-37 36 -40 42 -17 30 16 -8 35 -25 44 -36z"/>
<path d="M3088 9793 c-55 -56 -65 -71 -57 -86 9 -15 17 -12 70 35 50 43 57 53
44 61 -15 8 -14 12 6 33 12 13 18 24 13 24 -5 0 -40 -30 -76 -67z m52 2 c0
-12 -19 -12 -23 0 -4 9 -1 12 9 8 7 -3 14 -7 14 -8z m-49 -39 c-13 -13 -32
-26 -43 -29 -13 -4 -7 6 19 28 43 37 59 38 24 1z"/>
<path d="M9679 9714 c-11 -14 -4 -25 58 -85 40 -38 77 -69 83 -69 18 0 11 36
-13 63 -32 38 -43 34 -16 -5 l22 -33 -30 30 c-19 19 -26 33 -19 37 6 4 7 11 2
15 -4 4 -14 0 -21 -10 -7 -10 -16 -14 -19 -8 -3 5 0 11 6 14 7 2 9 8 5 13 -4
5 -12 2 -16 -5 -5 -8 -11 -9 -15 -3 -3 6 0 12 6 15 7 2 9 8 5 13 -4 5 -12 2
-17 -6 -7 -11 -10 -11 -10 -2 0 7 7 15 15 18 8 4 12 10 9 15 -8 13 -20 11 -35
-7z"/>
<path d="M2983 9688 c-10 -11 -17 -23 -15 -27 1 -3 -1 -6 -5 -6 -11 0 -46 -36
-141 -145 -63 -72 -82 -101 -79 -118 2 -12 0 -25 -4 -28 -5 -3 -9 3 -9 12 0
14 -8 10 -35 -16 -19 -19 -35 -38 -35 -43 0 -6 -18 -29 -40 -53 -22 -24 -40
-49 -40 -56 0 -8 3 -9 8 -3 4 6 13 10 21 10 8 0 16 6 19 14 3 9 0 11 -9 5 -11
-6 -11 -4 1 12 8 10 16 21 17 23 2 2 1 -10 -1 -28 -4 -35 -31 -61 -58 -56 -26
5 -41 -28 -27 -59 11 -24 14 -22 85 79 41 56 74 105 74 107 0 3 23 29 51 59
27 30 55 63 61 74 10 19 10 19 -8 5 -18 -14 -18 -14 -1 6 9 11 14 24 10 27 -3
4 -1 7 5 7 7 0 12 -5 12 -12 0 -9 3 -8 13 1 9 10 9 15 0 18 -13 5 -6 33 8 33
4 0 6 -7 2 -16 -4 -12 -2 -15 8 -12 8 3 13 12 13 21 -1 9 3 15 10 14 6 -1 10
5 8 13 -2 8 0 12 4 7 10 -10 55 33 47 46 -3 6 -1 7 5 3 11 -7 72 61 72 80 0
22 -28 23 -47 2z m16 -41 c-2 -1 -13 -9 -24 -17 -19 -14 -19 -14 -6 3 7 9 18
17 24 17 6 0 8 -1 6 -3z m-77 -69 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3
-1 -11 -7 -17z m-142 -152 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-72 -95 c-13 -15 -24 -24 -26 -20 -2 5 4 16 12 25 9
8 16 20 16 25 0 6 5 7 10 4 6 -4 1 -17 -12 -34z m-135 -188 c-5 -11 -8 -12
-13 -2 -5 7 -6 19 -3 26 5 11 8 12 13 2 5 -7 6 -19 3 -26z"/>
<path d="M9835 9559 c-4 -6 -14 -8 -22 -4 -14 5 10 -25 84 -105 18 -19 26 -22
31 -12 7 10 12 8 26 -7 15 -17 15 -19 1 -25 -14 -5 -12 -11 12 -43 15 -21 42
-54 60 -73 18 -19 36 -43 39 -52 3 -10 10 -16 15 -13 5 4 9 0 9 -8 0 -8 13
-30 30 -48 16 -19 32 -42 36 -52 3 -9 10 -15 15 -12 5 4 9 -2 9 -11 0 -9 10
-31 23 -48 13 -17 27 -40 31 -51 6 -16 9 -17 16 -5 5 8 6 18 4 22 -3 4 -1 8 5
8 6 0 8 -12 4 -32 -5 -24 -2 -34 13 -45 10 -8 19 -17 20 -21 2 -16 23 -60 31
-63 4 -2 21 -30 38 -62 29 -57 29 -57 42 -32 13 23 10 32 -39 112 -28 49 -60
95 -70 104 -10 9 -16 18 -14 20 3 2 -4 15 -14 29 -11 14 -20 19 -20 13 0 -22
-18 -14 -40 17 -16 22 -17 28 -6 24 11 -4 16 -1 16 10 0 9 -8 16 -17 16 -15 0
-16 2 -3 10 14 9 7 14 -13 11 -5 -1 -7 4 -4 11 2 7 -4 22 -15 34 -10 11 -31
39 -46 61 -50 76 -113 153 -123 153 -6 0 -7 4 -4 10 3 6 -1 7 -10 4 -12 -5
-13 -10 -4 -20 6 -8 14 -12 18 -10 4 3 12 -4 19 -15 7 -11 8 -19 2 -19 -5 0
-10 5 -10 11 0 5 -4 8 -9 4 -16 -9 -42 37 -29 52 12 15 7 23 -64 98 -26 28
-48 45 -48 40 0 -6 15 -27 34 -47 38 -40 38 -60 0 -25 -35 33 -56 79 -41 89 9
6 9 8 0 8 -6 0 -15 -5 -18 -11z m215 -255 c0 -12 -2 -12 -14 -2 -8 7 -12 16
-9 21 7 11 23 -2 23 -19z m58 -61 c9 -7 11 -13 5 -13 -7 0 -15 5 -18 10 -4 6
-11 7 -17 4 -7 -5 -9 -1 -5 9 3 10 2 17 -4 17 -6 0 -7 5 -3 11 4 8 10 5 17 -7
5 -11 17 -25 25 -31z m22 -43 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m40 -44 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0
6 5 11 10 11 6 0 10 -2 10 -4z m176 -273 c-10 -10 -36 26 -36 49 0 21 2 20 21
-11 11 -18 18 -36 15 -38z m54 -102 c0 -10 -7 -6 -20 14 -11 17 -20 35 -20 40
1 17 40 -36 40 -54z"/>
<path d="M9930 9420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2512 9118 c-7 -7 -12 -16 -12 -21 0 -5 -17 -29 -37 -54 -21 -26 -37
-47 -36 -47 1 -1 0 -11 -3 -23 -4 -13 -10 -20 -15 -17 -5 3 -4 -7 1 -23 9 -25
9 -26 -4 -10 -10 13 -16 15 -21 6 -5 -7 -1 -16 9 -21 24 -13 21 -31 -3 -18
-13 7 -22 7 -26 0 -3 -5 -1 -10 4 -10 6 0 11 -5 11 -11 0 -6 -7 -9 -15 -6 -19
8 -58 -42 -52 -65 3 -10 1 -16 -4 -13 -4 3 -11 -6 -15 -20 -3 -14 -10 -25 -15
-25 -9 0 -33 -50 -29 -59 1 -2 -3 -7 -8 -10 -17 -11 -46 -75 -44 -96 2 -17 1
-17 -7 -5 -9 13 -11 12 -11 -6 0 -11 5 -25 12 -32 9 -9 9 -12 0 -12 -7 0 -12
5 -12 10 0 6 -5 10 -11 10 -5 0 -7 -4 -4 -10 3 -5 1 -10 -4 -10 -6 0 -11 -4
-11 -9 0 -5 -29 -70 -65 -145 -36 -75 -65 -147 -65 -160 0 -14 -6 -31 -13 -38
-17 -17 -30 -51 -16 -43 6 4 8 -3 4 -22 -5 -21 -4 -24 4 -13 10 13 11 13 11 0
0 -8 9 5 20 29 25 54 25 63 0 31 l-20 -25 14 35 c7 19 12 42 10 50 -3 8 1 5 8
-8 12 -23 12 -23 23 10 6 18 8 42 4 53 -7 22 8 75 22 75 5 0 9 8 9 18 0 16 29
66 30 51 0 -12 -32 -84 -40 -89 -9 -5 -13 -27 -11 -50 3 -23 61 109 62 139 0
22 1 23 8 7 7 -18 8 -18 14 -2 4 10 5 22 1 27 -3 5 -1 9 4 9 11 0 52 91 52
115 0 9 4 14 8 11 4 -2 19 16 32 42 14 26 37 66 51 90 21 33 26 53 24 90 -2
26 -1 41 2 35 3 -7 9 -13 15 -13 6 0 7 8 3 18 -5 14 -4 15 6 5 10 -10 10 -16
1 -25 -7 -7 -12 -17 -12 -22 1 -19 30 27 30 46 0 10 4 18 10 18 5 0 22 19 36
43 15 23 36 56 46 72 18 27 17 27 -11 -5 -32 -35 -43 -31 -16 6 8 12 12 23 10
26 -2 2 5 5 16 5 30 3 78 66 59 78 -5 3 -10 12 -10 18 0 7 4 6 10 -3 5 -8 12
-11 16 -7 4 4 1 14 -7 22 -12 12 -17 13 -27 3z m-3 -70 c-7 -13 -14 -18 -16
-11 -3 7 2 19 9 27 18 20 22 12 7 -16z m-29 -13 c0 -8 -4 -15 -10 -15 -5 0 -7
7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-164 -297 c-9 -13 -19 -30 -23 -38
-3 -8 -19 -34 -34 -56 -16 -23 -26 -43 -24 -46 2 -2 -4 -4 -14 -4 -18 0 -18 2
-2 23 9 12 19 23 24 23 4 0 7 7 7 15 0 8 4 15 10 15 5 0 7 7 4 15 -4 8 -1 15
5 15 6 0 11 8 11 17 0 9 3 13 7 10 3 -4 12 2 20 13 8 11 17 20 20 20 3 0 -2
-10 -11 -22z m-133 -243 c-3 -9 -8 -14 -10 -11 -3 3 -2 9 2 15 9 16 15 13 8
-4z m-127 -252 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z"/>
<path d="M10220 9071 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"/>
<path d="M10415 8758 c-8 -31 2 -71 16 -62 6 3 9 0 7 -7 -4 -19 62 -150 72
-144 5 3 11 1 15 -5 3 -5 2 -10 -4 -10 -9 0 28 -92 43 -107 9 -10 -15 61 -25
72 -13 13 -11 22 5 22 13 0 23 -22 32 -67 1 -8 6 -26 10 -39 5 -19 4 -22 -6
-16 -9 5 -11 4 -6 -4 4 -6 11 -9 16 -6 4 3 10 -2 14 -10 3 -8 1 -15 -4 -15 -4
0 -1 -18 8 -39 l17 -39 12 26 13 27 -5 -28 c-3 -16 -2 -26 2 -24 5 3 9 -6 10
-21 0 -15 -1 -19 -4 -9 -2 9 -9 17 -14 17 -6 0 -6 -7 -1 -17 23 -44 62 -135
68 -158 3 -14 10 -25 15 -25 5 0 9 -10 9 -23 0 -13 10 -52 22 -87 l21 -65 12
35 c12 35 6 69 -17 98 -9 10 -13 10 -19 1 -4 -7 -6 -1 -6 14 2 21 5 23 16 14
12 -10 13 -9 7 8 -5 11 -15 39 -23 62 -17 50 -33 64 -33 31 -1 -20 -2 -21 -11
-5 -5 9 -7 23 -2 30 11 18 3 59 -15 81 -10 10 -15 19 -13 20 8 2 -23 76 -32
76 -4 0 -5 5 -1 12 4 6 3 8 -3 5 -6 -4 -14 0 -17 9 -4 9 -2 13 5 8 7 -4 10 -1
7 7 -3 8 -11 14 -18 14 -7 0 -15 6 -17 13 -3 6 -1 12 4 12 5 0 -6 32 -24 70
-30 63 -36 69 -53 60 -16 -9 -18 -8 -14 5 4 8 11 15 16 15 14 0 4 25 -10 26
-25 3 -27 5 -14 13 11 6 10 11 -3 25 -10 9 -15 19 -12 22 3 3 -4 20 -16 39
-12 18 -27 41 -33 51 -11 18 -12 17 -19 -8z m34 -43 c0 -5 -6 -1 -14 9 -8 11
-15 24 -15 30 0 5 7 1 15 -10 8 -10 14 -23 14 -29z m31 -29 c0 -2 -7 -7 -16
-10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m150 -352 c0 -8 -4 -12 -10 -9 -5 3
-10 13 -10 21 0 8 5 12 10 9 6 -3 10 -13 10 -21z m105 -232 c-3 -3 -5 0 -5 7
0 7 -4 10 -9 7 -5 -4 -7 4 -3 16 l5 22 9 -24 c5 -12 6 -25 3 -28z m30 -101 c3
-5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m12 -53 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M1966 8085 c-4 -11 -2 -22 6 -26 10 -7 10 -11 0 -21 -9 -9 -12 -9
-12 3 0 7 -7 2 -14 -13 -8 -15 -14 -41 -14 -58 1 -27 2 -28 14 -12 11 14 13
14 14 2 0 -8 9 3 20 25 10 22 16 43 14 47 -2 3 0 9 6 13 5 3 10 11 10 16 0 6
-5 7 -10 4 -6 -3 -14 4 -18 16 -8 20 -9 21 -16 4z m-15 -92 c-8 -15 -10 -15
-11 -2 0 17 10 32 18 25 2 -3 -1 -13 -7 -23z m25 1 c-4 -9 -9 -15 -11 -12 -3
3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z"/>
<path d="M1895 7888 c-18 -61 -18 -61 -5 -53 6 3 10 -3 10 -14 0 -23 -15 -28
-24 -8 -3 6 -3 1 0 -12 5 -17 3 -21 -6 -16 -10 6 -12 1 -8 -19 3 -14 1 -26 -4
-26 -6 0 -7 -7 -4 -16 3 -9 2 -12 -5 -9 -6 4 -8 -3 -4 -22 5 -22 4 -25 -4 -13
-8 11 -10 7 -6 -20 3 -19 2 -28 -1 -20 -10 20 -50 -133 -59 -221 -5 -50 -10
-67 -17 -58 -7 10 -8 6 -5 -13 2 -15 0 -29 -5 -30 -5 -2 -10 -36 -10 -76 -1
-41 -4 -68 -8 -62 -4 6 -5 -39 -4 -100 3 -98 2 -112 -14 -117 -22 -9 -32 -23
-32 -50 0 -19 6 -23 29 -23 l28 0 5 78 c3 42 9 86 13 96 6 14 5 17 -3 12 -8
-5 -10 -2 -6 8 3 9 2 28 -4 44 -7 20 -6 31 4 39 10 10 14 6 17 -20 3 -28 4
-27 5 10 1 24 -3 43 -10 45 -7 3 -9 11 -3 24 8 19 8 19 13 -1 3 -11 15 34 28
100 13 66 22 126 19 134 -3 8 -1 17 4 20 16 10 71 225 64 251 -4 14 -2 21 4
17 14 -9 33 37 26 64 -5 16 -3 20 7 14 10 -6 11 -2 6 19 -4 17 -3 25 3 22 9
-6 14 4 26 57 5 21 5 20 -9 -3 -11 -18 -15 -21 -16 -8 0 38 -20 25 -35 -24z
m19 -2 c-3 -15 -8 -25 -11 -23 -2 3 -1 17 3 31 3 15 8 25 11 23 2 -3 1 -17 -3
-31z m-105 -380 c-9 -8 -10 -7 -5 7 3 10 7 28 7 40 2 16 3 14 6 -7 3 -16 -1
-34 -8 -40z m-12 -38 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-16 -159 c-5 -13 -10 -19 -10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z m-24
-51 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M10795 7930 c-4 -6 -4 -23 -1 -36 4 -16 3 -23 -3 -19 -6 3 -3 -16 5
-42 9 -26 21 -66 28 -88 7 -22 17 -40 22 -40 5 0 8 -16 7 -35 -2 -30 -1 -31 5
-10 7 23 9 23 15 6 4 -11 3 -24 -3 -30 -5 -5 -10 -19 -9 -30 0 -21 0 -21 9 -1
l9 20 1 -20 c1 -12 5 -6 10 15 10 43 2 98 -12 78 -7 -9 -8 -3 -4 19 4 27 3 31
-11 26 -13 -5 -15 -2 -9 21 7 30 -11 99 -24 91 -4 -3 -8 11 -7 30 1 38 -16 65
-28 45z m25 -41 c0 -6 -4 -7 -10 -4 -5 3 -10 11 -10 16 0 6 5 7 10 4 6 -3 10
-11 10 -16z m-13 -51 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m37 -43 c4 -19 3 -23 -2 -12 -4 10 -12 18 -17 18 -9 -1 -10 2 -6 31 2 20 19
-4 25 -37z"/>
<path d="M10901 7610 c-1 -15 -6 -31 -13 -38 -9 -9 -9 -12 0 -12 10 0 10 -5 1
-21 -8 -15 -8 -24 0 -32 7 -7 12 -6 15 4 12 38 16 41 12 9 -3 -30 9 -93 30
-164 4 -11 2 -16 -4 -12 -5 3 -16 35 -23 71 -7 36 -16 65 -19 65 -4 0 4 -46
17 -102 13 -57 30 -143 37 -192 8 -49 18 -92 23 -95 4 -3 9 -40 10 -81 1 -61
6 -133 12 -182 1 -5 12 -8 26 -8 21 0 25 5 25 31 0 16 -4 28 -9 25 -5 -4 -9
25 -10 66 0 54 -4 74 -16 80 -8 5 -15 17 -15 26 1 15 3 14 16 -3 15 -19 16
-18 9 13 -8 37 -23 124 -32 187 -6 37 -7 38 -15 15 -6 -19 -7 -16 -3 14 5 44
-4 98 -15 91 -9 -6 -18 37 -25 121 -6 68 -33 166 -34 124z m76 -427 c-3 -10
-5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m28 -111 c-3 -3 -5 5 -5 17 0
14 -4 20 -12 15 -8 -5 -9 -2 -5 10 4 10 8 32 8 49 1 20 5 9 10 -27 5 -33 7
-62 4 -64z m13 -152 l0 -45 -9 50 c-4 27 -10 61 -13 75 -5 22 -3 21 9 -5 8
-16 14 -50 13 -75z"/>
<path d="M1703 7055 c-7 -41 -7 -60 2 -71 9 -13 12 -2 13 55 1 39 1 71 -1 71
-2 0 -8 -25 -14 -55z"/>
<path d="M1669 6023 c-3 -19 4 -49 13 -61 6 -7 8 -33 5 -58 -3 -32 -2 -42 5
-33 6 10 8 1 3 -26 -4 -31 -3 -37 5 -25 9 11 10 3 6 -35 -4 -32 3 -104 19
-199 14 -83 25 -154 25 -158 0 -4 5 -8 10 -8 6 0 10 7 10 15 0 8 4 15 10 15
10 0 3 -27 -12 -47 -6 -7 -6 -27 -2 -45 4 -18 9 -39 10 -47 2 -8 15 -63 31
-122 l27 -107 12 46 c14 54 7 112 -12 112 -8 0 -14 -7 -14 -16 0 -8 3 -13 8
-11 4 3 8 -15 8 -39 1 -25 0 -44 -1 -42 -2 2 -13 42 -25 90 -17 66 -19 91 -11
105 10 16 11 15 6 -7 -3 -14 -1 -38 4 -55 8 -27 9 -24 9 25 1 30 -3 64 -7 74
-7 18 -8 18 -14 2 -9 -23 -27 -11 -26 17 0 12 3 17 6 10 3 -10 7 -10 17 -1 16
16 8 57 -14 70 -14 9 -16 17 -9 42 5 17 9 40 8 51 -1 17 -2 16 -9 -5 -5 -17
-7 -6 -5 35 1 33 -4 83 -11 110 -7 28 -13 62 -14 77 0 15 -5 30 -10 33 -6 4
-8 15 -5 26 4 11 3 60 -1 109 -6 86 -8 90 -30 90 -13 0 -25 -3 -25 -7z m49
-158 c0 -29 0 -29 -8 -5 -4 14 -8 48 -8 75 l1 50 8 -45 c4 -25 7 -58 7 -75z
m12 -130 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10
-15z m10 -70 c1 -16 1 -37 0 -45 -3 -21 -18 29 -19 65 -1 38 17 20 19 -20z m7
-127 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"/>
<path d="M1671 5944 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M10996 5878 c-4 -46 -10 -94 -13 -107 l-6 -24 23 23 c19 19 22 30 18
77 -4 47 -3 52 7 33 12 -23 12 -23 18 6 3 16 8 39 12 52 7 19 4 22 -22 22
l-30 0 -7 -82z"/>
<path d="M11618 5953 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M11011 5761 c-9 -6 -12 -22 -9 -47 3 -22 3 -31 1 -21 -11 43 -33 2
-33 -60 0 -18 -5 -33 -10 -33 -6 0 -10 -12 -9 -27 0 -16 -9 -68 -20 -117 -21
-91 -26 -127 -12 -91 5 12 8 -6 7 -45 l0 -65 -12 45 -12 45 -3 -45 c-1 -25 -2
-61 -1 -80 1 -28 0 -31 -7 -15 -8 19 -10 18 -20 -9 -6 -16 -10 -31 -7 -33 2
-3 -2 -19 -9 -36 -7 -18 -11 -48 -9 -67 3 -26 2 -30 -4 -15 -8 17 -10 13 -16
-20 -4 -22 -11 -44 -15 -50 -4 -5 -22 -54 -40 -108 -25 -74 -31 -107 -26 -135
6 -35 6 -36 -10 -17 l-16 20 4 -20 c3 -11 8 -27 11 -35 4 -9 -1 -24 -9 -35
-16 -19 -16 -19 -5 3 12 24 -1 64 -14 43 -4 -6 -2 -11 5 -11 8 0 6 -7 -5 -19
-14 -16 -21 -39 -25 -84 -1 -5 -5 -5 -11 -2 -6 4 -10 -7 -10 -25 0 -18 4 -29
10 -25 6 4 5 -4 -1 -19 -11 -24 -29 -27 -29 -5 0 6 5 7 10 4 12 -8 13 5 1 24
-6 10 -12 3 -21 -25 -7 -22 -16 -43 -20 -48 -5 -5 -6 -15 -3 -22 3 -8 1 -14
-5 -14 -6 0 -16 -17 -23 -37 -6 -21 -15 -40 -19 -43 -9 -7 -70 -131 -70 -142
0 -5 -7 -16 -15 -25 -9 -8 -13 -23 -10 -33 4 -11 -3 -23 -18 -33 -13 -8 -29
-33 -36 -56 -6 -23 -17 -41 -22 -41 -6 0 -8 -7 -5 -16 3 -8 2 -12 -4 -9 -12 8
-36 -32 -45 -74 -5 -26 -4 -32 5 -26 9 5 11 4 6 -3 -11 -18 -26 -14 -26 5 0 9
-13 -4 -30 -30 -16 -26 -30 -55 -30 -65 0 -15 -2 -15 -10 -2 -7 11 -10 11 -10
2 0 -7 -11 -28 -25 -46 -14 -18 -30 -47 -36 -64 -6 -18 -15 -30 -19 -27 -11 7
-20 -22 -14 -42 5 -15 4 -16 -5 -3 -9 13 -12 12 -25 -5 -19 -25 -19 -32 -3
-38 10 -4 10 -6 0 -6 -7 -1 -14 -11 -14 -23 -1 -17 -2 -19 -6 -5 -7 25 -20 21
-40 -13 -9 -16 -21 -30 -25 -30 -4 0 -8 -9 -8 -21 0 -11 -4 -17 -10 -14 -5 3
-10 2 -10 -4 0 -6 9 -15 19 -21 16 -8 23 -5 41 19 12 18 16 32 10 36 -5 3 -14
-5 -20 -19 -11 -30 -17 -33 -15 -8 0 10 11 29 24 43 15 16 20 19 16 7 -13 -33
13 -18 44 25 17 23 35 48 41 54 8 9 7 19 -1 34 -7 13 -7 19 -1 15 6 -3 13 -2
17 4 3 5 12 10 18 10 7 0 4 -5 -5 -11 -10 -5 -18 -15 -18 -21 0 -6 9 -3 19 6
16 14 18 23 10 43 -13 35 -11 42 5 14 l13 -24 7 27 c3 14 11 26 16 26 6 0 10
7 10 15 0 8 -4 15 -9 15 -5 0 -11 -7 -15 -15 -3 -8 -11 -15 -17 -15 -7 0 -2
11 11 25 17 18 27 22 36 14 14 -12 29 5 19 21 -4 6 -1 9 7 8 8 -2 14 8 16 25
2 15 9 27 17 27 12 0 30 24 70 92 54 91 64 112 58 121 -3 5 -2 7 3 4 6 -3 16
3 23 15 11 18 10 19 -8 13 -13 -4 -21 -2 -21 5 0 6 5 8 10 5 6 -3 10 -1 10 5
0 7 6 10 13 7 14 -4 67 93 67 124 0 11 4 18 8 15 5 -3 20 20 35 50 15 29 23
54 18 54 -5 0 -11 7 -15 15 -4 12 -2 13 13 6 15 -8 21 -4 34 25 19 39 21 48 8
40 -14 -9 -21 22 -10 46 9 21 9 21 4 -4 -9 -48 18 -32 44 25 53 118 101 247
101 274 0 13 4 23 8 23 4 0 18 35 32 78 13 42 30 89 37 105 7 15 9 27 4 27 -5
0 -12 -6 -14 -12 -3 -8 -6 -8 -6 -2 -1 6 8 21 19 34 14 16 17 27 10 34 -5 5
-10 19 -9 30 0 19 2 18 11 -4 10 -24 11 -23 24 24 7 28 11 53 9 57 -3 3 3 21
12 39 9 19 17 52 17 76 1 23 4 40 7 39 7 -5 4 50 -5 90 l-6 30 11 -27 c13 -34
27 -36 27 -5 0 12 3 36 6 52 5 24 4 26 -3 10 -5 -11 -10 -14 -10 -7 -1 7 -5
10 -11 7 -12 -7 -13 5 -1 23 4 7 14 12 21 10 15 -3 28 32 28 77 0 25 -2 23
-12 -15 -11 -42 -13 -43 -18 -18 -3 15 -1 25 3 22 5 -3 10 10 11 28 2 51 3 56
11 64 4 4 7 -5 7 -21 0 -16 2 -26 4 -24 5 5 26 152 23 156 -2 1 -9 -2 -16 -6z
m-120 -623 c-10 -11 -11 -10 -6 5 4 10 2 17 -4 17 -6 0 -11 5 -11 12 0 8 6 6
17 -5 14 -14 15 -19 4 -29z m-66 -185 c-4 -10 -8 -29 -9 -43 -1 -14 -10 -37
-19 -50 l-18 -25 7 30 c4 17 11 33 17 36 5 4 7 12 3 17 -4 6 -1 20 6 32 16 25
23 26 13 3z m-54 -167 c-6 -8 -9 -22 -5 -32 5 -16 4 -17 -5 -3 -7 11 -7 26 2
49 8 21 13 26 15 16 2 -10 -1 -23 -7 -30z m-61 -190 c0 -13 -8 -30 -17 -37
-16 -13 -17 -12 -5 3 8 10 11 26 7 38 -4 11 -2 20 4 20 6 0 11 -11 11 -24z
m-120 -266 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-20 -36 c0 -9 -5 -12 -11 -8 -8 4 -9 -1 -5 -17 5 -18 5 -20 -3 -8
-12 18 -3 62 10 53 5 -3 9 -12 9 -20z m-30 -44 c0 -5 -4 -10 -10 -10 -5 0 -10
5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-16 -35 c3 -8 1 -15 -4 -15 -6 0
-10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-15 -47 c-9 -16 -14 -19 -17 -9
-2 7 2 17 9 22 20 13 21 11 8 -13z m-87 -160 c-7 -7 -12 -8 -12 -2 0 14 12 26
19 19 2 -3 -1 -11 -7 -17z m-34 -61 c-24 -37 -42 -49 -31 -21 6 16 46 61 51
58 1 -1 -8 -18 -20 -37z"/>
<path d="M1781 5494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1856 5117 c-7 -29 2 -86 17 -104 6 -9 6 -13 -1 -13 -7 0 -7 -10 -1
-32 6 -18 18 -60 28 -92 13 -45 19 -55 25 -41 3 10 3 21 -2 24 -5 3 -12 20
-16 36 l-7 30 20 -29 c19 -28 27 -101 10 -90 -12 7 -11 1 4 -30 8 -14 17 -23
21 -19 4 4 4 1 1 -8 -3 -8 6 -41 19 -74 20 -48 26 -56 32 -41 7 18 8 18 17 -5
6 -13 9 -28 8 -33 -1 -4 3 -14 9 -20 5 -7 6 -16 2 -20 -4 -4 -12 9 -19 31 -8
27 -12 31 -12 15 -1 -29 16 -63 41 -80 10 -7 18 -20 18 -28 0 -8 4 -14 10 -14
6 0 6 -10 -1 -27 -12 -33 2 -50 24 -28 15 14 15 19 -1 53 -9 20 -20 39 -24 42
-3 3 -14 32 -23 65 -10 33 -21 65 -25 70 -4 6 -10 23 -14 38 -4 15 -11 25 -16
22 -6 -3 -10 2 -10 12 0 15 -2 16 -10 3 -5 -8 -10 -10 -10 -4 0 5 4 14 10 20
10 10 -7 84 -19 84 -3 0 -7 12 -8 26 0 15 -11 39 -22 55 -12 16 -19 34 -16 39
6 10 -29 150 -44 178 -6 11 -10 9 -15 -11z m25 -54 c12 -30 22 -129 12 -120
-3 4 -7 23 -8 44 -2 21 -8 53 -13 71 -13 39 -6 43 9 5z m89 -273 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m134 -339 c3 -5
-1 -11 -9 -14 -9 -4 -12 -1 -8 9 6 16 10 17 17 5z"/>
<path d="M2041 4510 c0 -8 6 -26 14 -40 8 -14 14 -19 14 -10 0 8 -6 26 -14 40
-8 14 -14 19 -14 10z"/>
<path d="M2120 4410 c0 -18 -3 -21 -9 -11 -5 8 -11 9 -15 3 -8 -13 22 -44 35
-37 5 4 9 -1 9 -11 0 -13 -3 -14 -15 -4 -19 16 -19 0 0 -26 11 -15 16 -16 23
-6 7 10 13 7 26 -12 9 -14 12 -26 7 -26 -5 0 -13 5 -16 10 -3 6 -12 10 -18 9
-9 0 -9 -2 1 -6 6 -2 12 -11 12 -19 0 -8 5 -14 10 -14 6 0 10 -5 10 -11 0 -5
-4 -7 -10 -4 -14 9 -12 -7 4 -27 7 -10 17 -14 21 -10 5 4 5 2 2 -5 -6 -9 5
-35 68 -154 12 -23 27 -26 19 -4 -4 8 -1 15 5 15 6 0 11 8 11 18 -1 15 -2 15
-17 -3 -16 -20 -16 -18 2 28 2 6 -4 23 -15 37 -15 20 -20 21 -20 8 0 -9 8 -22
18 -28 16 -11 16 -11 -1 -6 -15 5 -17 2 -12 -17 5 -21 5 -21 -8 2 -11 19 -12
32 -4 55 8 22 7 31 -1 34 -7 2 -12 8 -12 13 0 24 -64 148 -73 142 -6 -4 -8 0
-5 8 3 8 -3 30 -13 49 -17 33 -18 33 -19 10z m110 -240 c0 -5 -2 -10 -4 -10
-3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z"/>
<path d="M2306 4053 c-4 -13 -4 -28 0 -34 4 -8 3 -9 -4 -5 -14 9 -15 -4 -2
-24 9 -13 10 -13 10 0 0 12 2 12 9 2 15 -25 22 16 7 51 -12 31 -13 31 -20 10z"/>
<path d="M2345 3983 c4 -15 0 -25 -12 -32 -10 -6 -12 -11 -5 -11 6 0 12 -6 12
-14 0 -8 10 -22 22 -30 12 -9 19 -16 15 -16 -4 0 -1 -13 8 -30 9 -16 19 -30
24 -30 5 0 12 -11 15 -24 5 -19 99 -157 146 -213 5 -7 10 -15 10 -18 0 -3 14
-24 32 -46 32 -40 52 -51 42 -24 -4 8 -2 15 4 15 12 0 36 -56 28 -68 -3 -5 22
-42 55 -83 34 -41 65 -81 69 -89 5 -8 12 -11 16 -7 5 5 4 11 -1 15 -6 4 -19
18 -30 32 -16 21 -14 20 13 -5 18 -16 30 -35 27 -43 -3 -8 17 -38 49 -71 29
-31 68 -71 85 -90 17 -19 33 -32 37 -29 3 4 -6 19 -21 33 -14 15 -24 29 -22
31 2 2 16 -8 31 -22 17 -18 24 -33 21 -46 -8 -23 4 -23 21 0 11 15 4 26 -59
93 -40 41 -86 91 -103 110 -17 18 -40 40 -52 48 -11 8 -19 18 -16 23 3 4 1 8
-5 8 -5 0 -13 10 -17 22 -4 13 -12 27 -19 33 -7 5 -34 37 -61 70 -26 33 -51
59 -55 58 -5 -2 -5 5 -2 14 5 11 3 14 -6 9 -7 -5 -11 -17 -8 -27 3 -11 1 -16
-5 -13 -5 3 -7 16 -4 27 6 24 -38 108 -56 104 -6 -1 -13 5 -15 13 -4 13 -3 13
6 0 8 -11 11 -11 11 -2 0 7 -6 15 -14 18 -7 3 -26 27 -40 55 -28 50 -40 59
-49 37 -3 -9 -9 -7 -20 7 -8 11 -13 24 -10 28 3 5 9 3 14 -4 4 -7 11 -10 14
-7 4 4 -10 34 -30 67 -38 61 -50 75 -38 44 4 -11 3 -14 -5 -9 -7 4 -12 19 -12
34 0 15 -10 38 -21 52 -19 23 -20 24 -14 3z m58 -120 c-3 -7 -6 -13 -8 -13 -2
0 -5 6 -8 13 -3 8 1 14 8 14 7 0 11 -6 8 -14z m32 -41 c-8 -8 -25 10 -25 26 0
13 2 13 14 -4 8 -10 13 -21 11 -22z m47 -84 c-7 -7 -12 -8 -12 -2 0 14 12 26
19 19 2 -3 -1 -11 -7 -17z m23 -18 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3
6 11 10 16 10 6 0 7 -4 4 -10z m89 -125 c9 -13 16 -30 15 -37 0 -9 -2 -8 -6 2
-3 8 -15 25 -26 38 -11 12 -16 22 -10 22 6 0 18 -11 27 -25z m136 -163 c0 -5
-7 -9 -15 -9 -8 0 -15 4 -15 9 0 4 7 8 15 8 8 0 15 -4 15 -8z m33 -54 c6 -22
5 -22 -8 -4 -17 22 -20 40 -6 32 5 -3 11 -16 14 -28z m120 -144 c-4 -11 -9
-12 -19 -3 -17 14 -18 23 -2 13 8 -5 9 -2 4 11 -5 15 -4 16 8 6 8 -7 12 -19 9
-27z m47 -63 c0 -7 -8 -3 -19 10 -11 13 -17 28 -14 32 6 10 32 -23 33 -42z
m31 -24 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6 -4 6 -10 -1 -17z"/>
<path d="M2660 3460 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M9955 3398 c-15 -16 -24 -32 -21 -35 3 -3 16 6 28 22 18 23 23 25 32
13 9 -12 14 -13 23 -3 9 10 6 15 -12 23 -20 9 -27 6 -50 -20z"/>
<path d="M9965 3374 c-9 -15 -22 -23 -31 -20 -8 3 -19 -4 -25 -16 -7 -13 -7
-18 -1 -14 6 3 13 3 16 -2 2 -4 -5 -9 -16 -9 -24 -2 -77 -52 -78 -72 0 -8 -3
-12 -6 -9 -9 9 -79 -73 -76 -88 2 -9 -3 -11 -14 -7 -14 5 -16 3 -11 -11 4 -11
3 -15 -4 -10 -16 9 -81 -68 -66 -79 8 -6 7 -7 -4 -4 -9 3 -34 -11 -60 -34 -43
-38 -55 -67 -34 -81 6 -5 6 -8 -2 -8 -7 0 -24 -11 -39 -25 -29 -27 -54 -34
-34 -10 7 8 19 15 26 15 8 0 14 3 15 8 0 4 2 15 4 25 2 9 -1 15 -6 11 -5 -3
-7 -10 -4 -15 4 -5 0 -9 -8 -9 -7 0 -24 -11 -37 -26 -20 -20 -22 -27 -11 -34
12 -7 11 -11 -2 -25 -28 -27 -19 -30 11 -4 83 72 123 121 117 141 -6 18 -5 19
15 8 28 -15 46 5 27 30 -9 12 -9 13 3 3 15 -14 34 -18 27 -5 -6 9 19 33 27 25
3 -4 7 -1 7 5 1 7 2 18 3 25 0 6 6 12 12 12 6 0 36 24 65 53 50 49 53 54 36
66 -13 10 -15 16 -6 22 7 4 17 0 24 -9 9 -13 16 -9 48 30 20 25 37 53 37 62 0
13 2 13 9 3 6 -11 10 -10 16 5 5 10 9 21 9 25 1 4 11 16 24 28 12 11 22 27 22
35 0 21 -5 19 -25 -11z m-82 -112 c-3 -12 -9 -19 -14 -16 -5 3 -3 14 3 24 15
24 19 21 11 -8z m-34 -43 c-6 -12 -13 -18 -16 -15 -3 3 -1 12 5 19 7 8 8 18 4
22 -4 5 -1 5 6 1 9 -5 9 -12 1 -27z m-63 -51 c-3 -18 -25 -55 -26 -43 0 20 13
55 20 55 5 0 7 -6 6 -12z m-100 -102 c0 -14 -5 -21 -9 -17 -4 4 -5 15 -1 24 9
24 12 21 10 -7z m44 20 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z"/>
<path d="M3044 3054 c3 -8 2 -12 -4 -9 -6 3 -10 2 -10 -2 0 -5 39 -44 86 -88
48 -44 98 -91 112 -105 13 -14 29 -26 36 -26 6 -1 16 -2 21 -3 6 -1 4 -5 -2
-9 -9 -5 2 -19 33 -43 40 -31 50 -34 70 -25 l24 11 -22 17 c-13 9 -26 14 -30
12 -4 -3 -8 0 -8 5 0 6 -4 11 -10 11 -18 0 -10 -22 10 -27 11 -3 20 -9 20 -15
0 -9 -37 8 -56 26 -14 14 -14 46 0 46 5 0 1 6 -9 14 -11 8 -23 12 -27 10 -5
-3 -12 6 -18 20 -5 14 -14 26 -20 26 -6 0 -30 20 -53 45 -47 49 -57 54 -57 28
0 -13 -3 -14 -12 -5 -8 8 -8 15 3 28 9 12 10 15 2 10 -7 -4 -13 -3 -13 1 0 12
-49 63 -61 63 -6 0 -8 -7 -5 -16z m41 -24 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16
10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m110 -120 c21 -22 36 -40 33 -40 -9 0
-78 66 -78 74 0 11 5 7 45 -34z m65 -65 c11 -13 10 -14 -4 -9 -9 3 -16 10 -16
15 0 13 6 11 20 -6z m35 -5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4
10 3 0 8 -4 11 -10z"/>
<path d="M9418 2843 c0 -5 -2 -12 -3 -18 -2 -5 -3 -16 -4 -22 0 -7 -9 -13 -18
-13 -15 0 -16 2 -3 10 8 5 12 11 10 14 -3 2 -16 -5 -29 -15 -31 -25 -17 -44
22 -30 26 10 62 63 50 75 -9 8 -23 8 -25 -1z"/>
<path d="M9324 2757 c-6 -7 -32 -27 -57 -44 -45 -31 -60 -53 -36 -53 5 0 7 -4
4 -10 -3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 12 19 -16 10 -53 -17 -43 -31
-64 -50 -43 -38 9 5 12 4 7 -3 -4 -7 -14 -12 -22 -12 -8 0 -13 -4 -10 -9 8
-12 -21 -34 -37 -28 -7 3 -24 -4 -38 -15 l-24 -19 25 12 c23 11 24 11 11 -4
-7 -10 -24 -17 -37 -17 -13 0 -24 -4 -24 -8 0 -5 -12 -14 -26 -20 -14 -7 -22
-17 -19 -22 3 -5 0 -7 -8 -4 -8 3 -18 0 -22 -5 -3 -6 -11 -8 -17 -5 -7 4 -8 2
-4 -4 4 -7 1 -12 -9 -12 -9 0 -13 -5 -9 -11 4 -7 1 -10 -7 -7 -8 3 -34 -9 -59
-26 -25 -17 -76 -47 -112 -66 -37 -19 -64 -38 -62 -42 3 -4 -5 -8 -18 -9 -22
0 -22 -1 -3 -8 11 -5 25 -7 30 -7 6 1 0 -2 -12 -8 -13 -5 -23 -15 -23 -20 -1
-6 -6 -2 -13 9 -12 20 -12 20 -54 -1 -23 -12 -39 -24 -36 -28 4 -3 -1 -6 -10
-6 -20 0 -71 -19 -66 -25 3 -2 23 2 44 10 46 16 74 19 60 6 -12 -10 -91 -29
-124 -30 -13 0 -29 -5 -35 -12 -8 -9 -4 -10 19 -5 22 5 26 4 15 -4 -8 -5 -11
-10 -6 -10 16 0 130 42 149 55 17 12 17 13 0 19 -17 7 -17 8 -1 17 12 6 24 6
36 0 21 -11 77 19 77 41 0 7 12 13 26 14 37 1 61 12 54 24 -4 7 -13 7 -26 -1
-36 -19 -39 -5 -4 18 24 16 33 18 29 8 -11 -28 42 -18 66 13 12 15 18 21 15
14 -10 -19 34 4 57 30 9 11 19 19 22 19 17 -2 23 1 48 23 14 14 28 23 31 20 2
-3 24 12 48 32 24 20 54 40 67 45 13 5 20 13 17 19 -3 5 1 10 10 10 16 0 79
41 100 65 6 6 30 26 55 44 25 19 35 30 23 27 -12 -4 -24 -3 -27 2 -3 5 6 10
19 10 14 0 44 12 66 26 38 26 40 28 22 41 -22 17 -25 18 -39 2z m36 -16 c0
-12 -56 -34 -72 -28 -7 3 2 8 20 12 17 4 32 11 32 16 0 5 5 9 10 9 6 0 10 -4
10 -9z m-192 -143 c-2 -6 -10 -14 -16 -16 -7 -2 -10 2 -6 12 7 18 28 22 22 4z
m-168 -128 c-24 -15 -37 -11 -16 5 11 8 22 12 25 9 3 -3 -1 -9 -9 -14z m-80
-50 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m-80 -44 c0 -20 -13 -27 -30 -15 -12 7 -12 9 3 9 10 0 15 5 12 10 -3 6 -1 10
4 10 6 0 11 -6 11 -14z m-70 -50 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M3385 2730 c-7 -12 2 -30 16 -30 6 0 7 5 4 10 -3 6 -1 10 5 10 6 0 9
-8 6 -17 -3 -13 8 -27 37 -48 61 -44 87 -57 87 -45 0 5 5 10 10 10 6 0 10 -7
10 -15 0 -8 -6 -15 -12 -15 -7 0 -2 -7 10 -16 12 -8 26 -13 31 -9 5 3 13 0 17
-6 4 -8 3 -9 -4 -5 -7 4 -12 4 -12 1 0 -3 13 -11 30 -16 16 -6 28 -15 25 -19
-4 -6 19 -22 28 -19 1 1 20 -15 41 -34 21 -20 42 -35 45 -34 3 1 22 -10 42
-26 20 -15 40 -27 44 -27 5 0 16 -7 26 -16 14 -13 22 -13 39 -4 17 9 21 9 15
0 -5 -9 -1 -10 16 -6 23 7 23 7 5 -8 -20 -16 -20 -16 0 -31 10 -8 37 -24 59
-35 22 -11 54 -29 70 -40 17 -11 38 -20 48 -20 10 0 15 -4 12 -10 -3 -6 1 -7
10 -4 12 5 13 9 4 20 -7 8 -7 14 -1 14 5 0 15 -6 21 -14 9 -11 8 -15 -6 -20
-13 -5 -4 -14 38 -36 30 -16 63 -29 73 -30 10 0 24 -7 31 -16 9 -13 16 -14 33
-5 11 6 27 11 34 11 7 -1 2 -7 -12 -15 l-25 -13 29 -12 c16 -6 33 -13 39 -17
6 -3 7 -1 3 5 -5 8 -2 10 8 6 9 -3 14 -11 13 -16 -1 -6 4 -13 12 -16 8 -2 11
0 8 5 -4 6 8 7 30 4 20 -3 38 -12 41 -20 3 -10 0 -11 -16 -2 -11 6 -27 11 -34
10 -20 -1 73 -40 106 -45 16 -2 32 -7 35 -12 3 -5 24 -12 45 -16 25 -5 35 -10
27 -15 -8 -5 24 -20 84 -40 107 -34 125 -37 143 -19 6 6 14 9 17 6 3 -3 -1
-13 -10 -22 -15 -15 -15 -16 7 -18 13 0 29 -1 35 -1 7 1 11 -3 8 -7 -3 -4 8
-10 23 -13 46 -9 156 -39 178 -49 14 -7 28 -6 42 1 11 6 27 11 34 11 7 -1 2
-7 -11 -14 l-24 -13 24 -5 c173 -39 371 -63 300 -36 -13 4 -40 6 -60 3 -31 -5
-33 -4 -17 6 15 9 34 9 81 0 34 -6 74 -11 89 -11 15 0 25 -4 22 -9 -3 -5 -23
-7 -45 -4 -23 3 -41 1 -45 -6 -4 -6 0 -12 8 -12 59 -5 82 -3 88 6 5 6 8 2 8
-8 0 -14 5 -17 20 -12 10 3 29 1 41 -5 19 -10 114 -19 271 -24 25 0 44 -4 41
-8 -2 -5 6 -8 19 -9 138 -3 209 -1 215 4 9 10 183 9 183 0 0 -5 19 -9 43 -10
38 -1 39 0 12 7 -19 5 3 7 60 4 102 -4 197 4 189 17 -3 5 25 7 63 4 37 -3 91
-2 118 3 28 6 40 10 28 11 -13 0 -23 5 -23 10 0 15 18 23 22 10 2 -6 28 -11
58 -11 73 0 128 4 113 10 -7 2 -13 8 -13 13 0 6 8 6 19 -1 26 -13 44 -13 35 1
-5 8 3 10 27 6 38 -7 72 6 44 17 -9 3 -35 4 -58 1 l-42 -5 35 10 c37 10 100 5
100 -9 0 -4 14 -6 30 -3 17 2 30 8 30 13 0 5 14 9 31 9 16 0 28 4 25 8 -6 10
112 37 121 28 3 -3 -5 -6 -18 -7 -13 0 -40 -7 -59 -15 -70 -28 8 -9 229 56 41
12 80 23 87 24 8 1 17 5 20 9 4 4 18 7 31 7 16 0 22 4 17 12 -5 7 -2 9 9 5 13
-5 142 34 155 48 10 9 -19 5 -40 -6 -14 -8 -23 -8 -31 0 -8 8 -6 11 8 11 16 0
17 2 6 9 -8 5 -21 5 -30 0 -9 -4 -68 -24 -131 -43 -63 -19 -109 -35 -102 -35
6 -1 12 -8 12 -18 0 -14 -2 -15 -17 -1 -16 13 -28 12 -116 -11 -54 -15 -94
-31 -90 -35 4 -5 -2 -5 -14 -2 -15 5 -23 3 -23 -4 0 -8 -8 -10 -22 -7 -28 8
-118 -12 -118 -25 0 -6 -11 -8 -26 -4 -34 9 -232 -23 -263 -41 -13 -8 -21 -11
-17 -5 4 6 -4 8 -21 5 -16 -3 -47 -5 -70 -5 -24 1 -43 -3 -43 -9 0 -6 -19 -8
-47 -5 -37 4 -41 3 -18 -3 21 -6 3 -9 -65 -8 -52 0 -138 -1 -190 -3 -92 -3
-93 -4 -35 -10 l60 -7 -72 -1 c-39 -1 -73 2 -75 7 -3 10 -301 16 -363 7 -30
-4 -34 -4 -15 3 18 7 6 8 -40 5 -51 -3 -59 -1 -35 6 27 8 25 9 -12 5 -24 -3
-43 -1 -43 4 0 6 -20 8 -50 4 -33 -4 -55 -2 -65 7 -13 11 -14 10 -9 -4 4 -10
18 -16 38 -17 17 -1 45 -5 61 -10 21 -6 11 -7 -35 -4 -36 3 -66 10 -68 15 -2
6 -12 10 -22 10 -10 0 -26 3 -35 6 -13 6 -14 9 -3 16 9 7 4 8 -14 4 -16 -4
-28 -2 -28 4 0 5 -8 10 -17 10 -36 0 -299 42 -315 51 -22 11 -84 23 -120 22
-15 0 -25 3 -23 7 9 15 -79 32 -107 21 -25 -9 -97 4 -85 15 2 3 16 0 31 -5 28
-11 59 -5 50 10 -3 5 -10 6 -16 3 -6 -4 -13 -2 -17 3 -10 15 -127 44 -146 36
-12 -4 -16 -3 -12 4 8 12 -77 45 -88 35 -3 -4 -9 -2 -13 4 -14 20 -88 44 -100
32 -7 -7 -15 -8 -19 -4 -4 4 -2 11 5 15 9 5 -6 15 -43 29 -31 12 -61 20 -66
16 -5 -3 -9 -1 -9 3 0 13 -69 42 -73 30 -1 -5 -21 0 -42 12 -22 11 -33 21 -25
21 23 1 -112 69 -138 70 -13 0 -20 4 -17 10 4 6 -1 7 -11 3 -16 -6 -15 -7 2
-20 26 -19 9 -16 -33 7 -37 20 -37 24 2 21 12 -1 -2 11 -32 29 -28 17 -64 32
-80 33 -17 1 -30 7 -30 14 0 7 5 9 10 6 6 -4 7 -1 3 6 -5 7 -14 10 -22 7 -8
-3 -14 -1 -14 4 0 5 -10 14 -21 21 -17 9 -20 8 -13 -3 4 -7 -5 -1 -22 14 -25
24 -139 98 -151 98 -2 0 -9 5 -16 10 -30 25 -89 60 -102 60 -8 0 -14 5 -12 12
1 7 -7 13 -19 14 -22 1 -77 34 -70 41 3 3 20 -5 38 -18 31 -21 32 -21 15 -2
-10 12 -32 27 -48 34 -17 6 -43 24 -58 38 -102 98 -169 138 -186 111z m105
-65 c0 -8 9 -17 20 -20 11 -4 18 -10 15 -15 -5 -9 -27 5 -66 42 -9 9 -20 14
-23 11 -3 -4 -6 -2 -6 4 0 19 13 22 36 6 13 -8 24 -21 24 -28z m250 -195 c13
-11 19 -20 13 -20 -5 0 -18 9 -28 20 -10 11 -16 20 -13 20 3 0 15 -9 28 -20z
m80 -50 c0 -5 7 -10 15 -10 8 0 15 -5 15 -10 0 -7 -6 -7 -19 0 -24 12 -36 30
-22 30 6 0 11 -4 11 -10z m76 -48 c-4 -3 -14 2 -24 12 -16 18 -16 18 6 6 13
-6 21 -14 18 -18z m154 -92 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10
-5 10 6 0 17 -5 25 -10z m85 -40 c-3 -5 -13 -10 -21 -10 -8 0 -12 5 -9 10 3 6
13 10 21 10 8 0 12 -4 9 -10z m185 -85 c17 -9 30 -18 30 -20 0 -10 -29 -4 -42
8 -7 8 -20 16 -28 20 -10 4 -11 6 -2 6 7 1 26 -6 42 -14z m67 -51 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m208 -74 c15 -6 11 -8 -18 -8 -24
1 -33 4 -25 9 15 10 17 10 43 -1z m132 -76 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6
6 11 1 17 -2 13 -5z m81 -19 c14 -14 -9 -13 -39 0 l-34 16 34 -6 c19 -3 37 -7
39 -10z m3086 -24 c-17 -4 -36 -11 -42 -15 -7 -4 -12 -2 -12 5 0 10 -3 10 -12
1 -7 -7 -21 -12 -32 -11 -41 1 30 24 81 26 40 1 43 0 17 -6z m-2908 -29 c-10
-2 -22 0 -28 6 -6 6 0 7 19 4 21 -5 23 -7 9 -10z m2744 -18 c0 -2 -7 -4 -15
-4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z m-2595 -27 c19 -15 21
-14 -37 -8 -5 1 -8 6 -8 11 0 14 23 12 45 -3z m2505 3 c13 -9 13 -10 0 -10 -8
0 -22 5 -30 10 -13 9 -13 10 0 10 8 0 22 -5 30 -10z m-50 -10 c0 -5 -7 -10
-16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-63 -16 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2206 -37 c-7 -7 -21 -7 -43
0 l-33 10 43 0 c29 0 40 -3 33 -10z m74 -7 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16
10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m55 -10 c-8 -5 -22 -9 -30 -9 -10 0 -8
3 5 9 27 12 43 12 25 0z m1625 -50 c-3 -5 -21 -10 -38 -9 -31 1 -31 1 -7 9 36
11 52 11 45 0z m-362 -27 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13
-5z m160 0 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-748 -13
c-3 -5 -23 -10 -43 -9 -30 0 -33 2 -17 9 28 12 67 12 60 0z m118 -5 c5 -11 1
-12 -16 -6 -12 4 -29 6 -37 4 -13 -4 -13 -3 0 6 21 15 47 13 53 -4z m70 8 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m352 -3 c22 -6 13 -7 -32
-6 -34 1 -66 5 -69 9 -9 9 62 7 101 -3z"/>
<path d="M3905 2340 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M8540 2206 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2870 2162 c0 -5 11 -14 24 -22 49 -26 43 -48 -64 -245 -15 -27 -55
-102 -89 -166 -67 -125 -90 -146 -137 -129 -31 12 -34 12 -34 2 0 -10 172
-102 190 -102 7 0 -2 13 -19 29 -17 16 -31 37 -31 46 0 17 30 77 168 333 82
151 80 159 49 -216 -12 -151 -20 -275 -17 -277 3 -2 113 84 245 190 132 106
242 192 244 190 5 -5 -219 -425 -242 -454 -8 -9 -24 -11 -54 -7 -24 3 -43 4
-43 2 0 -6 281 -156 292 -156 6 0 -4 13 -21 29 -17 16 -31 35 -31 41 0 28 243
479 268 498 9 7 25 10 35 7 56 -17 60 -18 53 -7 -3 6 -54 36 -113 67 -94 50
-110 55 -128 44 -11 -7 -91 -71 -178 -142 -87 -71 -160 -127 -163 -125 -4 5
16 268 31 404 l6 52 -116 61 c-125 65 -125 65 -125 53z"/>
<path d="M9553 2089 c-79 -38 -143 -74 -143 -80 0 -7 7 -8 18 -3 9 4 31 8 47
8 28 1 33 -5 88 -112 l58 -112 -102 -50 c-56 -28 -104 -50 -106 -50 -9 0 -103
210 -103 229 0 11 15 31 33 45 55 44 14 29 -148 -51 -131 -65 -155 -81 -155
-100 0 -31 -23 -40 -115 -45 -105 -6 -187 -42 -251 -110 -64 -69 -87 -125 -92
-230 -4 -80 -2 -92 25 -150 39 -84 79 -128 147 -162 162 -82 398 -8 473 149
12 26 14 40 6 57 -11 23 -11 23 -24 -7 -50 -113 -133 -171 -237 -163 -98 7
-167 79 -219 230 -33 95 -31 206 6 260 37 54 81 79 150 85 71 7 132 -18 181
-74 17 -19 34 -32 37 -29 4 3 -10 50 -30 103 -29 74 -34 99 -25 105 24 16 61
21 77 11 23 -14 250 -472 250 -505 1 -19 -7 -33 -24 -44 -14 -9 -25 -20 -25
-25 0 -7 116 46 258 120 48 24 52 38 7 21 -48 -18 -63 -4 -120 111 -30 60 -55
114 -55 122 0 10 177 107 195 107 11 0 115 -224 115 -246 0 -15 -10 -29 -29
-40 -17 -10 -28 -21 -25 -26 3 -4 71 25 152 65 174 85 157 76 149 83 -3 4 -20
1 -37 -4 -17 -6 -38 -8 -46 -5 -20 7 -254 478 -254 509 0 13 13 33 33 48 53
42 10 29 -140 -45z"/>
<path d="M8312 2123 c-6 -2 -10 -9 -6 -14 3 -5 -4 -9 -14 -9 -11 0 -46 -12
-78 -27 -33 -14 -62 -26 -66 -25 -5 1 -8 -2 -8 -8 0 -5 9 -10 19 -10 11 0 23
5 26 10 3 6 12 10 18 10 7 0 3 -7 -7 -15 -22 -17 -81 -21 -71 -5 8 13 0 13
-23 0 -31 -17 -107 -48 -135 -55 -34 -9 -27 -21 15 -28 39 -5 60 1 51 16 -4 6
3 8 19 4 17 -5 30 -2 38 8 6 7 18 11 26 8 10 -4 12 0 7 12 -5 15 -2 17 16 12
24 -6 43 1 174 61 73 34 76 37 55 49 -24 13 -36 14 -56 6z m48 -19 c0 -2 -15
-10 -34 -19 -27 -13 -39 -14 -53 -5 -17 11 -17 11 0 6 21 -7 60 11 52 24 -3 4
4 6 15 3 11 -3 20 -7 20 -9z m-102 -47 c14 4 14 3 3 -5 -17 -12 -43 -4 -34 11
5 8 8 8 10 0 3 -7 12 -9 21 -6z m-248 -87 c-12 -8 -12 -10 -1 -10 8 0 12 -2 9
-5 -3 -3 -13 -2 -22 1 -14 5 -15 9 -4 15 7 5 18 9 23 9 6 0 3 -5 -5 -10z"/>
<path d="M4435 2100 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M10063 1802 c-28 -4 -63 -47 -63 -76 0 -54 58 -93 110 -75 91 32 49
167 -47 151z"/>
<path d="M5728 1723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3871 1599 c-21 -46 -36 -88 -34 -92 3 -4 22 10 43 32 41 42 104 67
143 56 90 -25 157 -50 157 -59 -1 -6 -124 -108 -275 -226 -151 -118 -275 -218
-275 -221 0 -3 51 -23 113 -44 61 -21 184 -64 271 -97 87 -32 163 -58 168 -58
11 0 110 201 103 209 -3 3 -22 -13 -43 -35 -80 -88 -150 -100 -297 -51 -55 18
-100 38 -100 44 0 6 59 58 130 115 72 57 195 154 274 217 103 82 140 116 130
122 -8 4 -109 41 -224 82 -116 41 -218 78 -228 82 -14 6 -24 -8 -56 -76z"/>
<path d="M6348 1643 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M8200 1526 c-249 -79 -297 -92 -395 -111 -120 -22 -498 -105 -503
-110 -7 -6 30 -165 38 -165 4 0 11 20 14 45 9 56 43 98 94 113 71 21 64 36
126 -264 55 -263 57 -276 40 -295 -9 -10 -25 -19 -35 -19 -10 0 -21 -4 -24
-10 -3 -5 0 -10 7 -10 34 -1 318 64 318 72 0 5 -9 6 -20 3 -27 -9 -58 2 -70
25 -6 11 -31 118 -56 237 -58 280 -63 307 -58 312 2 2 26 6 54 9 56 5 99 -9
121 -40 30 -43 47 -63 52 -58 3 3 -1 33 -9 68 -8 35 -12 67 -9 72 7 11 36 13
59 4 19 -7 176 -480 176 -531 0 -30 -5 -36 -37 -50 -68 -29 -15 -26 80 5 51
16 167 53 260 82 190 61 176 46 152 174 -21 108 -28 111 -38 18 -8 -64 -46
-115 -112 -147 -47 -23 -145 -46 -145 -34 0 2 -18 58 -40 123 -22 66 -39 120
-37 122 1 1 18 5 37 8 45 8 94 -21 117 -69 19 -39 38 -47 30 -12 -8 37 -75
250 -88 282 -13 29 -13 27 -19 -43 -4 -65 -8 -77 -34 -98 -45 -40 -57 -30 -93
81 -47 145 -49 136 27 162 36 12 81 22 100 23 43 0 96 -31 118 -70 9 -16 22
-30 29 -30 8 0 3 27 -14 83 -27 89 -31 97 -41 96 -4 0 -81 -24 -172 -53z"/>
<path d="M4545 1200 c-193 -247 -242 -300 -275 -300 -10 0 -21 -4 -24 -8 -3
-4 42 -20 100 -34 57 -14 104 -21 104 -16 0 4 -11 13 -24 18 -41 15 -48 41
-25 87 38 76 42 78 160 49 56 -14 106 -29 111 -34 4 -4 12 -36 16 -70 10 -75
0 -98 -42 -90 -14 3 -29 2 -31 -2 -4 -7 268 -80 297 -80 7 0 1 10 -14 23 -33
27 -37 41 -92 367 -55 320 -57 330 -66 330 -4 0 -92 -108 -195 -240z m105 -75
c10 -60 18 -111 16 -113 -2 -2 -45 7 -95 18 -66 16 -89 25 -84 34 15 24 137
175 140 172 2 -1 12 -52 23 -111z"/>
<path d="M5030 1352 c0 -4 13 -13 28 -20 56 -24 59 -45 33 -230 -41 -295 -49
-335 -72 -354 -12 -10 -34 -18 -50 -18 -16 0 -29 -4 -29 -8 0 -9 220 -45 228
-38 2 3 -12 12 -32 20 -60 25 -61 36 -26 282 17 120 33 220 34 222 3 3 148
-237 272 -450 69 -120 104 -163 104 -128 0 16 59 407 75 499 8 46 20 90 26 97
6 7 25 14 42 16 47 4 39 22 -13 29 -166 23 -195 21 -127 -11 26 -12 33 -22 35
-50 4 -36 -27 -286 -38 -307 -3 -7 -61 83 -131 205 l-126 217 -104 17 c-124
20 -129 20 -129 10z"/>
<path d="M3477 1268 c-43 -33 -40 -100 5 -132 52 -36 118 -1 118 63 0 65 -74
106 -123 69z"/>
<path d="M6692 1269 c-105 -18 -193 -84 -239 -177 -25 -50 -28 -68 -28 -152 0
-83 3 -102 27 -150 33 -68 96 -132 157 -162 71 -34 200 -38 286 -7 78 27 141
83 182 161 26 48 28 62 28 158 0 94 -3 110 -27 155 -73 139 -218 204 -386 174z
m149 -53 c67 -45 105 -186 97 -350 -8 -143 -52 -214 -144 -232 -45 -8 -116 25
-141 67 -68 111 -81 349 -25 458 41 80 141 106 213 57z"/>
<path d="M5950 1255 c-114 -32 -202 -110 -241 -214 -28 -74 -24 -205 9 -267
48 -94 153 -166 270 -185 77 -13 214 -1 288 25 l39 13 5 90 c5 97 17 123 56
123 13 0 24 5 24 10 0 6 -17 10 -38 10 -22 0 -95 3 -163 6 -120 7 -142 1 -76
-21 38 -12 43 -30 39 -135 l-3 -79 -45 -7 c-105 -15 -185 21 -225 103 -21 43
-23 62 -23 168 1 128 14 190 55 249 73 106 216 110 319 7 26 -26 53 -64 59
-84 7 -20 16 -37 20 -37 9 0 21 98 21 171 0 47 -14 65 -26 34 -10 -25 -39 -26
-101 -1 -79 33 -189 41 -263 21z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
